import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function QuarantineSoundtracks() {
  document.body.style.backgroundColor = "#fbf2d7";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Khota Sikka"
                src="/images/quarantine_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Quarantine Soundtracks</div>
            <div className="tags">
              Music &nbsp;|&nbsp; Songwriting &nbsp;|&nbsp; Concept
              &nbsp;|&nbsp; CGI &nbsp;|&nbsp; Video
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                What started as the spread of a virus in a distant country,
                Coronavirus slowly overtook the whole world and brought
                everyone’s life to a standstill in unimaginable ways.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/quarantine_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/quarantine_sq_02.jpg"
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                While waiting for this time to pass and for things to get
                better, we decided to create music that would help lift up
                people’s spirits, motivate them and also help them introspect
                about how we can evolve and improve our actions when we go back
                outside. The world could do with as much positivity as possible
                and we wanted to do our bit to add to it.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <iframe
                src="https://www.youtube.com/embed/cqCbWYSfnXc"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                alt="Quarantine Soundtrack - We will survive"
                title="Quarantine Soundtrack - We will survive"
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/quarantine_sq_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/quarantine_sq_04.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <iframe
                src="https://www.youtube.com/embed/ULkRY2Tprw8"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                alt="Quarantine Soundtrack - Khud se puch"
                title="Quarantine Soundtrack - Khud se puch"
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/quarantine_sq_05.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/quarantine_sq_06.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <iframe
                src="https://www.youtube.com/embed/B2RlCC-hzK0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                alt="Quarantine Soundtrack - Let's go back better"
                title="Quarantine Soundtrack - Let's go back better"
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/atl_btl_ftl_cover.jpg"
          title="ATL BTL FTL"
          alt="ATL BTL FTL"
          text="Breaking barriers one chant at a time"
          path="/atl-btl-ftl"
        />

        <NextProject
          src="/images/fake_song_cover_01.jpg"
          title="Fake Song"
          alt="Fake Song"
          text="Moving tunes for when the world stood still"
          path="/fake-song"
        />
      </section>
    </>
  );
}
