import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

export default function FriendlyLaundry() {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#DBF5F9";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Friendly Laundry"
                src="/images/friendly-laundry_cover.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Friendly Laundry</div>
            <div className="tags">
              Naming &nbsp;|&nbsp; Identity & Branding &nbsp;|&nbsp; Wayfinding
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>A laundry that is friendly to the planet and the people.</span><br/>

              {/* <span>This is a small peek into the project. More Coming Soon.</span> <br/> */}
            
              {/* <span className="info soon">More Coming Soon</span> */}
            </div>
          </section>

          {/* <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="Friendly Laundry"
              src="/images/fl_hd_01.jpg"
            />
          </section> */}
          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Friendly Laundry"
                src="/images/fl_rectangle_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Friendly Laundry"
                src="/images/fl_rectangle_02.jpg"
              />
            </div>
          </section>
          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="Friendly Laundry"
              src="/images/fl_hd_02.jpg"
            />
            <LazyLoadImage
              className="project__item__img hd_cover"
              alt="Friendly Laundry"
              src="/images/fl_hd_03.jpg"
            />
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/self-matters_cover.jpg"
          title="SELF MATTERS"
          alt="Self Matters"
          text="Focusing on the importance of self care & wellness"
          path="/selfmatters"
        />

        <NextProject
          src="/images/furry-essentials_cover.jpg"
          title="Furry Essentials"
          alt="Furry Essentials"
          text="Pampering is a must for our furry favourites"
          path="/furry-essentials"
        />
      </section>
    </>
  );
}
