import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../assets/scss/PreviousProject.scss";
import "../assets/scss/Variables.scss";
// import { HiArrowNarrowRight } from "react-icons/hi";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

function PreviousProject(props) {
  return (
    <>
      <section id="previous_project">
        <Link className="previous_project" to={props.path}>
          <div className="previous-tab">
            <h3>previous project</h3>
            <div className="arrow-wrapper">
              <div className="previous-arrow" />
            </div>
          </div>
          <motion.div
            exit={{ opacity: 0 }}
            transition={transition}
            className="details"
          >
            <motion.figure
              className="cards__item__pic-wrap"
              data-category={props.label}
            >
              <motion.img
                className="cards__item__img"
                src={props.src}
                transition={transition}
              />
            </motion.figure>
            <motion.div className="cards__item__info">
              <div className="title">{props.title}</div>
              <div className="text">{props.text}</div>
            </motion.div>
          </motion.div>
        </Link>
      </section>
    </>
  );
}
export default PreviousProject;
