import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function StudioADAD() {
  document.body.style.backgroundColor = "#ebebeb";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <video
              src="/videos/studio_adad_am.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>

          <section className="project_summary_container">
            <div className="title">STUDIO AD+AD</div>
            <div className="tags">Identity & Branding</div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              Studio AD+AD is the result of a collaboration between a
              brother-sister duo who are an architect and interior designer,
              respectively. They create and transform spaces together with their
              combined skill-set.
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <video
                src="/videos/studio_adad_am_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              We played with the two different perspectives that the
              collaboration naturally brought with it to define the brand
              language. The identity lives at the intersection of where the
              exterior meets the interior, the north meets the south and the
              function meets form to create a complete space. Instead of
              creating two opposing views, we unified them to create a seamless
              transition. We designed the identity to be flexible and dynamic -
              expanding and contracting to hold the elements of the brand
              together.
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_02.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_03.jpg"
              />
              <video
                src="/videos/studio_adad_am_02.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover padding-bottom"
              alt="Studio AD+AD"
              src="/images/studio_adad_hd_01.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_04.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_05.jpg"
              />
              <video
                src="/videos/studio_adad_am_03.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_06.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_07.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Studio AD+AD"
                src="/images/studio_adad_sq_08.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover"
              alt="Studio AD+AD"
              src="/images/studio_adad_hd_02.jpg"
            />
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/khota-sikka_cover.jpg"
          title="Khota Sikka"
          alt="Khota Sikka"
          text="Redefining the public restroom"
          path="/khota-sikka"
        />

        <section id="next_project">
          <Link className="next_project" to="/topical">
            <div className="next-tab">
              <h3>Next project</h3>
              <div className="arrow-wrapper">
                <div className="next-arrow" />
              </div>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              className="details"
            >
              <motion.figure className="cards__item__pic-wrap">
                <motion.video
                  className="cards__item__img"
                  alt="Topical"
                  autoPlay
                  loop
                  muted
                  playsInline
                  src="/videos/topical_cover_am.mp4"
                  transition={transition}
                />
              </motion.figure>
              <motion.div className="cards__item__info">
                <div className="title">Topical</div>
                <div className="text">
                  Evolving constantly to keep up with the times
                </div>
              </motion.div>
            </motion.div>
          </Link>
        </section>
      </section>
    </>
  );
}
