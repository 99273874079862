import React from "react";
import Cards from "../components/Cards";

// import ScrollToTop from "../components/ScrollToTop";

export default function Work() {
  return (
    <>
      {/* <ScrollToTop /> */}
      <Cards />
    </>
  );
}
