import React from "react";
import "../assets/scss/App.scss";
import "../assets/scss/HeroSection.scss";

function HeroSection() {
  return (
    <section className="hero-container">
      <video src="/videos/rocking.mp4" autoPlay loop muted playsInline />
    </section>
  );
}

export default HeroSection;
