import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useLocation } from "react-router-dom";

import NextProject from "../components/NextProject";
import ScrollToTop from "../components/ScrollToTop";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const Swati = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#fbf5f1";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <motion.div
          onAnimationComplete={() => setCanScroll(true)}
          className="scroll"
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <div className="project__item__img">
                <div
                  className="frame-single"
                  // initial={{
                  //   // y: "20%",
                  //   width: imageDetails.width,
                  //   height: imageDetails.height,
                  // }}
                  // animate={{
                  //   y: 0,
                  //   width: "100%",
                  //   height: "70vh",
                  //   transition: { delay: 0.2, ...transition },
                  // }}
                >
                  <div whileHover="hover" transition={transition}>
                    <img
                      alt="Swati"
                      src="/images/swati_cover.jpg"
                      style={{ scale: scale }}
                    initial={{ scale: 1.0 }}
                    animate={{
                      transition: { delay: 0.2, ...transition },
                      
                    }}
                    />
                  </div>
                </div>
              </div>
            </figure>
          </section>

          <motion.section className="project_summary_container">
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, ...transition },
              }}
              className="title"
            >
              Swati
            </motion.div>
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.8, ...transition },
              }}
              className="tags"
            >
              Branding &nbsp;|&nbsp; Website Design
            </motion.div>
          </motion.section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <motion.div
              initial={{
                opacity: 0,
                y: 80,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 1.5, ...transition },
              }}
              className="info"
            >
              <span>
              SWATI is a global research-oriented pharmaceutical company with a vision to take science to market. Headquartered in Mumbai, with a distinguished history spanning over five decades. For the past twenty-five years, they have dedicated ourselves to addressing neglected diseases.
              </span>
            </motion.div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_02.jpg"
              />
              </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/swati_hd_01.jpg"
            />
          </section>
          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_04.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_05.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_06.jpg"
              />
              </div>
          </section>

          
          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/swati_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_07.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/swati_rectangle_08.jpg"
              />
              </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/swati_hd_03.jpg"
            />
          </section>
        </motion.div>
      </div>

      <section className="project-nav">
        <section id="next_project">
            <Link className="next_project" to="/tira">
              <div className="next-tab">
                <h3>Next project</h3>
                <div className="arrow-wrapper">
                <div className="next-arrow" />
              </div>
              </div>
              
              <motion.div
                exit={{ opacity: 0 }}
                transition={transition}
                className="details"
              >
                <motion.figure className="cards__item__pic-wrap">
                  <motion.video
                    className="cards__item__img"
                    alt="Topical"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/tira_cover.mp4"
                    transition={transition}
                  />
                </motion.figure>
                <motion.div className="cards__item__info">
                  <div className="title">Tira Beauty</div>
                  <div className="text">
                  Making a playful introduction
                  </div>
                </motion.div>
              </motion.div>
            </Link>
          </section>
      </section>
    </>
  );
};

export default Swati;
