import React from "react";
// import gsap from "gsap";
// import { Power3 } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import "../assets/scss/InfoSection.scss";
import "../assets/scss/InfoPage.scss";
// import ScrollToTop from "../components/ScrollToTop";

export default function Info() {
  document.body.style.backgroundColor = "#F3F6DA";
  // document.getElementById("footerLine").style.display = "none";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="info-page">
        <section className="logo-container">
          <div className="rocking-logo"></div>
        </section>

        <section
          className="info_page_container"
          style={{ justifyContent: "center" }}
        >
          <div className="info">
            Brands aren’t built in a day, they are built over time. And we
            believe that the time we spend over it needs to be worthwhile - for
            us and for you. We value working with people who share our beliefs -
            the ones who believe that in design lies the power to make a
            difference, that right intentions are as important as smart ideas and that having some rocking company through the process makes it
            all the better.
            <br></br>
            <br></br>
            We’re an independent design studio based out of Mumbai, led by Riddhi
Parikh and Monish Ganesan. We work collaboratively with clients to find dynamic, creative and meaningful solutions for brands in an ever-evolving world.
          </div>
        </section>

        <section
          className="info_page_container_mini"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <h3>We do</h3>

          <div>
            Identity & Branding &nbsp;|&nbsp; Naming &nbsp;|&nbsp; Packaging
            Design &nbsp;|&nbsp; Website Design &nbsp;|&nbsp; Social Media
            Content Creation &nbsp;|&nbsp; Motion Design & Films &nbsp;|&nbsp;
            Music &nbsp;|&nbsp; Space Design &nbsp;|&nbsp; Photography
            &nbsp;|&nbsp; Print
          </div>
        </section>

        <section className="info_page_container_mini">
          <h3>Reach us at</h3>

          <a href="mailto:hello@rocking.company">
            <h2>hello@rocking.company</h2>
          </a>
          <h2>+91 90221 31939</h2>
        </section>
      </div>
    </>
  );
}
