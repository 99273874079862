import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const ANewKnot = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#fef5ee";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/ank_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">A New Knot</div>
            <div className="tags">Identity & Branding</div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              A New Knot is a bespoke event planning service that creates
              elegant weddings and celebrations for a tasteful clientele. With a
              sharp eye for detail paired with a flair for creativity, this team
              of young experts work with the best in the industry to deliver
              events that you will remember for a lifetime.
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/ank_rectangle_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/ank_rectangle_02.jpg"
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                We were approached with the task of refreshing their identity
                and visual language. We set out with crafting the logotype and
                incorporating the knot within its letterforms. The knot extends
                fluidly and ties into the visual language while the colours
                channel the luxurious and celebratory spirit that the brand
                represents.
              </span>
              <br />
              <br />
              <span>
                The result is a brand that is elevated, sophisticated and
                assured - a brand that you could trust to deliver a memorable
                experience for the important events of your life.
              </span>
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/ank_sq_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/ank_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/ank_rectangle_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/ank_rectangle_04.jpg"
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/oye-kake_cover.jpg"
          title="Oye Kake"
          alt="Oye Kake"
          text="Adding a zing of colour to amp up the flavour of culture"
          path="/oye-kake"
        />

        <section id="next_project">
          <Link className="next_project" to="/awakeful">
            <div className="next-tab">
              <h3>Next project</h3>
              <div className="arrow-wrapper">
                <div className="next-arrow" />
              </div>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              className="details"
            >
              <motion.figure className="cards__item__pic-wrap">
                <motion.video
                  className="cards__item__img"
                  alt="Awakeful"
                  autoPlay
                  loop
                  muted
                  playsInline
                  src="/videos/awakeful_hd_01.mp4"
                  transition={transition}
                />
              </motion.figure>
              <motion.div className="cards__item__info">
                <div className="title">Awakeful</div>
                <div className="text">
                  Opening your eyes to an exciting world
                </div>
              </motion.div>
            </motion.div>
          </Link>
        </section>
      </section>
    </>
  );
};

export default ANewKnot;
