import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

export default function Awakeful() {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#f1f7fb";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Awakeful"
                src="/images/awakeful_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Awakeful</div>
            <div className="tags">Identity & Branding</div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              Awakeful Experiences is a luxury travel company that delivers
              customized experiences that are immersive, rich in exposure,
              tasteful and thoughtful. They encourage the travelers to be awake
              and aware of the surroundings, soak in the experiences and
              understand the world a little better. The identity we designed is
              a visual metaphor of being awake to the world. It also acts as a
              strong graphic element across the brand, creating a strong recall
              value while still allowing the visuals to take centre stage.
            </div>
          </section>

          <section className="project_grid_container">
            <video
              className="hd_cover padding-bottom"
              src="/videos/awakeful_hd_01.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Awakeful"
                src="/images/awakeful_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Awakeful"
                src="/images/awakeful_sq_02.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="Awakeful"
              src="/images/awakeful_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container">
            <video
              className="hd_cover padding-bottom"
              src="/videos/awakeful_hd_03.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/awakeful_sq_03.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Awakeful"
                src="/images/awakeful_sq_04.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Awakeful"
                src="/images/awakeful_sq_05.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Awakeful"
                src="/images/awakeful_sq_06.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <video
              className="hd_cover"
              src="/videos/awakeful_website.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/ank_cover.jpg"
          title="A New Knot"
          alt="A New Knot"
          text="Binding momentous occasions with elegance"
          path="/a-new-knot"
        />

        <NextProject
          src="/images/aat_cover.jpg"
          title="All About Them"
          alt="All About Them"
          text="Bringing four-leggeds the attention they deserve"
          path="/aat"
        />
      </section>
    </>
  );
}
