import React, { useState, useEffect, useRef } from "react";
import "../assets/scss/Footer.scss";
import { Link } from "react-router-dom";
import SocialMedia from "./SocialMedia";
import ContactModel from "./ContactModel";

const useClickOutside = (handler) => {
  const domNode = useRef();

  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

function Footer() {
  const [isOpenSM, setIsOpenSM] = useState(false);
  const [isOpenCD, setIsOpenCD] = useState(false);

  const domNode = useClickOutside(() => {
    setIsOpenSM(false);
    setIsOpenCD(false);
  });

  return (
    <footer className="footer-container">
      <h1 id="footerLine">
        A great brand is built with smart ideas, the right intentions <br />
        and some rocking company.
        <br />
        Got a project for us? Drop us a&nbsp;
        <a href="mailto:hello@rocking.company">hello@rocking.company</a>
      </h1>

      <SocialMedia
        open={isOpenSM}
        onClose={(SocialMedia) => setIsOpenSM(false)}
      >
        <div ref={domNode} className="social-icons">
          <div className="social-icon-link__header">Follow us on</div>

          <a
            className="social-icon-link"
            href="https://www.instagram.com/rocking.company/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            Instagram
          </a>
          <a
            className="social-icon-link"
            href="https://www.facebook.com/rocking.company/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="FaceBook"
          >
            Facebook
          </a>
          <a
            className="social-icon-link"
            href="https://tinyurl.com/rocking-company-youtube"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            Youtube
          </a>
          <a
            className="social-icon-link"
            href="https://tinyurl.com/Spotify-RockingCompany"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Spotify"
          >
            Spotify
          </a>
          <a
            className="social-icon-link"
            href="https://in.linkedin.com/company/rocking-company"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            LinkedIn
          </a>
        </div>
      </SocialMedia>

      <ContactModel
        open={isOpenCD}
        onClose={(ContactModel) => setIsOpenCD(false)}
      >
        <div
          ref={domNode}
          className="social-icons"
          style={{ justifyContent: "center", gap: "1rem" }}
        >
          <span className="social-icon-link__header">Reach us at</span>
          <a className="social-icon-link" href="mailto:hello@rocking.company">
            hello@rocking.company
          </a>
          <span className="social-icon-link__header">or</span>
          <span className="social-icon-link">+91 90221 31939</span>
        </div>
      </ContactModel>

      <div className="footer-link-wrapper">
        <Link className="footer-links" to="/work">
          Work
        </Link>
        <Link className="footer-links" to="/info">
          Info
        </Link>
        <div
          className="footer-links"
          onClick={() => setIsOpenSM((isOpenSM) => !isOpenSM)}
        >
          Social
        </div>
        <div
          className="footer-links"
          onClick={() => setIsOpenCD((isOpenCD) => !isOpenCD)}
        >
          Contact
        </div>
      </div>
    </footer>
  );
}

export default Footer;
