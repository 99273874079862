import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function ScrollingAllDay() {
  document.body.style.backgroundColor = "#fbf2d7";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Scrolling All Day"
                src="/images/scrolling-all_day_cover.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container padding-bottom">
            <div className="title">Scrolling All Day</div>
            <div className="tags padding-bottom">
              Music &nbsp;|&nbsp; Songwriting &nbsp;|&nbsp; Concept
              &nbsp;|&nbsp; CGI &nbsp;|&nbsp; Video
            </div>
          </section>

          {/* <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                This ties in with the essence of the song, which is to break
                barriers and to challenge the status quo, to go back to a fresh,
                unfiltered approach. We believe in creating work that explores
                new avenues, is unshackled and unique, is clear of baggage and
                free of the tried-and-tested approach. The song captures the
                core of our philosophy and our attitude. This song is our
                anthem.
              </span>
            </div>
          </section> */}

          {/* <section className="project_info_container">
            <div className="info_left">
              <span>
                This ties in with the essence of the song, which is to break
                barriers and to challenge the status quo, to go back to a fresh,
                unfiltered approach. We believe in creating work that explores
                new avenues, is unshackled and unique, is clear of baggage and
                free of the tried-and-tested approach. The song captures the
                core of our philosophy and our attitude. This song is our
                anthem.
              </span>
            </div>
          </section> */}

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/New_year_HiJUmp_002_1_1.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/runneronPhone_004_1.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/Iheartscroll_001_1.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/stairwayToCell_001_1.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/Toilet_phone_looped_006_1.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              {/* <video
                src="/videos/Toilet_phone_unlooped_006_1.mp4"
                autoPlay
                loop
                muted
                playsInline
              /> */}
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/fake_song_cover_01.jpg"
          title="Fake Song"
          alt="Fake Song"
          text="Moving tunes for when the world stood still"
          path="/fake-song"
        />

        <NextProject
          src="/images/launda-befikar_cover.jpg"
          title="LAUNDA BEFIKAR"
          alt="Launda Befikar"
          text="Launda Befikar by MC Heam"
          path="/launda-befikar"
        />
      </section>
    </>
  );
}
