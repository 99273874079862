import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Mumkin() {
  document.body.style.backgroundColor = "#fbf8d5";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Mumkin</div>
            <div className="tags">Identity & Branding</div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                Mumkin (stands for 'possible' in Hindi) is a movement by Bal
                Asha Trust, one of India’s most trusted charitable organizations
                since 1985, that is committed to providing life changing quality
                care to abandoned and destitute children. Their key mission is
                to create actionable change across the country towards child
                protection, rescue and positive integration with a caring
                family.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/mumkin_hd_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                The logo captures the essence of this movement, which aims to
                bring back smiles to these children’s faces. It instantly
                engages the viewer and evokes a positive, hopeful feeling
                without diluting the sincerity of the subject. The same concept
                is extended into the imagery and the visual identity.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_sq_02.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_sq_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_sq_04.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_sq_05.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/mumkin_sq_06.jpg"
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/aat_cover.jpg"
          title="All About Them"
          alt="All About Them"
          text="Bringing four-leggeds the attention they deserve"
          path="/aat"
        />

        <NextProject
          src="/images/kinstrukto_cover.jpg"
          title="Kinstrukto"
          alt="Kinstrukto"
          text="Time to unblock creativity"
          path="/kinstrukto"
        />
      </section>
    </>
  );
}
