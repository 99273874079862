import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Kinstrukto() {
  document.body.style.backgroundColor = "#fde5c6";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <video
              src="/videos/kinstrukto_am_01.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>

          <section className="project_summary_container">
            <div className="title">Kinstrukto</div>
            <div className="tags">
              Identity & Branding &nbsp;|&nbsp; Packaging Design
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                Crafted with miniature blocks, Kinstrukto timepieces allow you
                to create, personalize and decorate your watch at your will.
                Customization is at the core of their philosophy, making it
                appealing to every age group.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <video
                src="/videos/kinstrukto_am_02.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/kinstrukto_sq_01.jpg"
              />
              <video
                src="/videos/kinstrukto_am_03.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                The product already came with its own visual elements, and so,
                the challenge was to create a bold brand language representing
                the product without overpowering it. We achieved that with a
                visual language designed from the blocks, by throwing the
                spotlight on them. The forms and the colours are all derived
                from the product. The logotype is set in a modern sans serif
                with a subtle nod to the blocks in the ‘k’ which also acts as
                the brand mark. The resulting geometry in the visuals creates a
                playful yet sophisticated vibe.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover padding-bottom"
              alt="Kinstrukto"
              src="/images/kinstrukto_hd_01.jpg"
            />
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/kinstrukto_am_04.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/kinstrukto_sq_02.jpg"
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                The packaging of the product – a block box, extends this same
                thought and takes it off the screen and into the hands of the
                customers.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover padding-bottom"
              alt="Kinstrukto"
              src="/images/kinstrukto_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/kinstrukto_sq_03.jpg"
              />
              <video
                src="/videos/kinstrukto_am_05.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/kinstrukto_am_06.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
      <PreviousProject
          src="/images/mumkin_cover.jpg"
          title="Mumkin"
          alt="Mumkin"
          text="Instilling hope one smile at a time"
          path="/mumkin"
        />

        <NextProject
          src="/images/atl_btl_ftl_cover.jpg"
          title="ATL BTL FTL"
          alt="ATL BTL FTL"
          text="Breaking barriers one chant at a time"
          path="/atl-btl-ftl"
        />
      </section>
    </>
  );
}
