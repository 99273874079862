import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useLocation } from "react-router-dom";

import NextProject from "../components/NextProject";
import ScrollToTop from "../components/ScrollToTop";
// import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1 };


const WeNeighborhood = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 2]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  // document.body.style.backgroundColor = "#fffbe4";
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>    
      <ScrollToTop />
      <div className="project">
        <div
          onAnimationComplete={() => setCanScroll(true)}
          className="scroll"
          // initial="initial"
          // animate="animate"
          // exit="exit"
        >
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <div className="project__item__img">
                <div
                  className="frame-single"
                  // initial={{
                  //   // y: "20%",
                  //   width: imageDetails.width,
                  //   height: imageDetails.height,
                  // }}
                  // animate={{
                  //   y: 0,
                  //   width: "100%",
                  //   height: "70vh",
                  //   transition: { delay: 0.2, ...transition },
                  // }}
                >
                  <div whileHover="hover" transition={transition}>
                    <img
                      alt="WeNeighborhood"
                      src="/images/wn_cover.jpg"
                      style={{ scale: scale }}
                    initial={{ scale: 1.0 }}
                    animate={{
                      transition: { delay: 0.2, ...transition },
                      
                    }}
                    />
                  </div>
                </div>
              </div>
            </figure>
          </section>

          <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 1.2, ...transition },
          }}
          >
          <section className="project_summary_container">
            <motion.div
              className="title"
            >
              We:Neighborhood
            </motion.div>
            <motion.div
              className="tags"
            >
              Identity & Branding &nbsp;|&nbsp; Packaging
              &nbsp;|&nbsp; Illustration &nbsp;|&nbsp; Photography
            </motion.div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <motion.div
              className="info"
            >
              We:Neighborhood is a sprawling brewery located in Central Bangalore. With its sustainable practices, the brewery has set out to reinvent the local bar experience - entirely solar powered, minimising food waste, sourcing greens locally, reusable takeaway packaging and a lot more.
            </motion.div>
          </section>

          <section className="project_grid_container padding-bottom">
            <motion.div 
            className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_02.jpg"
              />
              </motion.div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
            We worked closely with the founders to develop the concept, bringing to their attention the staggering amount of waste a restaurant creates and how making mindful changes could lead to a positive difference.
            </span>
              <br></br><br></br>
              <span>We then set out to articulate this into the branding and visual identity. The typography, hand drawn illustrations, warm colour palette and brand messaging work cohesively to keep the local vibe instant and create a vibrant and fresh atmosphere. The result is a space that not only brings people together for a good time but also extends to including the environment and being thoughtful towards it.</span>

            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_01.jpg"
            />
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_04.jpg"
              />
              </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_03.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_05.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_06.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_07.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_08.jpg"
              />
              </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_04.jpg"
            />
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_05.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_09.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_10.jpg"
              />
              </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_06.jpg"
            />
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_07.jpg"
            />
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_08.jpg"
            />
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="WeNeighborhood"
              src="/images/wn_hd_09.jpg"
            />
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_11.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="WeNeighborhood"
                src="/images/wn_rectangle_12.jpg"
              />
              </div>
          </section>
          </motion.div>
          
        </div>
        
      </div>

      <section className="project-nav">
        <section id="previous_project">
            <Link className="previous_project" to="/tira">
              <div className="previous-tab">
                <h3>Previous project</h3>
              </div>
              <motion.div
                exit={{ opacity: 0 }}
                transition={transition}
                className="details"
              >
                <motion.figure className="cards__item__pic-wrap">
                  <motion.video
                    className="cards__item__img"
                    alt="Topical"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/tira_cover.mp4"
                    transition={transition}
                  />
                </motion.figure>
                <motion.div className="cards__item__info">
                  <div className="title">Tira Beauty</div>
                  <div className="text">
                  Making a playful introduction
                  </div>
                </motion.div>
              </motion.div>
            </Link>
          </section>

          <NextProject
          src="/images/pause_cover.jpg"
          title="PAUSE RESTROOMS"
          alt="Pause Restrooms"
          text="Reimagining the public restroom"
          path="/pause-restrooms"
        />
      </section>
    </>
  );
};

export default WeNeighborhood;
