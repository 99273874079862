import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const transition = { duration: 1.5 };

function CardItem(props, imageDetails, image) {
  return (
    <>
      <div className="cards__item">
        <Link className="cards__item__link panel" to={props.path} id={props.id}>
          <motion.figure
            className="cards__item__pic-wrap"
            ref={image}
            style={{
              width: imageDetails.width,
              height: imageDetails.height,
            }}
            data-category={props.label}
          >
            <motion.img
              className="cards__item__img"
              alt={props}
              src={props.src}
              transition={transition}
              whileHover={{ paddingBottom: "5rem" }}
            />
          </motion.figure>
          <motion.div exit={{ opacity: 0 }} className="cards__item__info">
            <div className="title">{props.title}</div>
            <div className="text">{props.text}</div>
            {/* <div className="tags">{props.tags}</div> */}
          </motion.div>
        </Link>
      </div>
    </>
  );
}

export default CardItem;
