import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import NextProject from "../components/NextProject";
// import ScrollToTop from "../components/ScrollToTop";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1 };

// const PauseRestrooms = ({ imageDetails }) => {
//   const { scrollYProgress } = useViewportScroll();
//   const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]);

//   const [canScroll, setCanScroll] = useState(false);

//   useEffect(() => {
//     if (canScroll === false) {
//       document.querySelector("body").classList.add("no-scroll");
//     } else {
//       document.querySelector("body").classList.remove("no-scroll");
//     }
//   }, [canScroll]);

export default function PauseRestrooms() {

  document.body.style.backgroundColor = "#FEECE7";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div
          // onAnimationComplete={() => setCanScroll(true)}
          className="scroll"
          // initial="initial"
          // animate="animate"
          // exit="exit"
        >
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <div className="project__item__img">
                <div
                  className="frame-single"
                  // initial={{
                  //   width: imageDetails.width,
                  //   height: imageDetails.height,
                  // }}
                  // animate={{
                  //   width: "100%",
                  //   height: "100%",
                  //   transition: { delay: 0.2, ...transition },
                  // }}
                >
                  <div whileHover="hover" transition={transition}>
                    <img
                      alt="Pause Restrooms"
                      src="/images/pause_banner.jpg"
                      // style={{ scale: scale }}
                      // initial={{
                      //   scale: 0.5,
                      //   position: "relative",
                      // }}
                      // animate={{
                      //   scale: 1,
                      //   position: "absolute",
                      //   transition: { delay: 0.2, ...transition },
                      //   // y: window.innerWidth > 1440 ? -1200 : -600,
                      // }}
                    />
                  </div>
                </div>
              </div>
            </figure>
          </section>

          <section className="project_summary_container">
            <div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                scrub: true,
                transition: { delay: 0.8, ...transition },
              }}
              className="title"
            >
              PAUSE RESTROOMS
            </div>
            <div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                scrub: true,
                transition: { delay: 1.2, ...transition },
              }}
              className="tags"
            >
              Naming &nbsp;|&nbsp; Identity & Branding &nbsp;|&nbsp; Wayfinding
              &nbsp;|&nbsp; Illustration
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div
              initial={{
                opacity: 0,
                y: 80,
              }}
              animate={{
                opacity: 1,
                y: 0,
                scrub: true,
                transition: { delay: 1.8, ...transition },
              }}
              className="info"
            >
              In India, public restrooms are usually built without much thought,
              their upkeep is neglected over a period of time and are poorly
              perceived by people. Pause restrooms are here to change that. They
              are more than just toilets - they are hygiene centres that provide
              a safe, clean and rejuvenating experience to the common public,
              with special facilities for truck drivers.
            </div>
          </section>

          <section className="project_banner padding-bottom">
            <video
              src="/videos/pause_am_01.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_05.jpg"
              />
              <video
                src="/videos/pause_am_02.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_02.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_04.jpg"
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                Clean, simple and striking was the approach of our brand
                language. We worked on the nomenclature as well as the brand
                identity.
              </span>
              <br />
              <br />
              <span>
                The name “Pause” works as a reminder to weary travellers on the
                highway to take a breather. It connected with the logo we
                designed – a pause sign combined with the restroom doors, which
                helps drive home the brand message together with the name, as
                one cohesive unit.
              </span>
              <br />
              <br />
              <span>
                Using a combination of striking colours- red and white, with
                clean lines and easy to understand graphics, we also designed
                the wayfinding system and iconography to reinforce the same
                concept.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="Pause Restrooms"
              src="/images/pause_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_06.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Pause Restrooms"
                src="/images/pause_sq_07.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover padding-bottom"
              alt="Pause Restrooms"
              src="/images/pause_hd_03.jpg"
            />
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover"
              alt="Pause Restrooms"
              src="/images/pause_hd_04.jpg"
            />
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                With this project, we are hoping to make a mark and inspire a
                change in the standards of all other restrooms across the
                country.
              </span>
              <br />
              <br />
              <span>
                This project is open to being replicated in other places across
                the country. If you are interested in rebuilding this at another
                location, you can get in touch with us at hello@rocking.company
              </span>
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
      <PreviousProject
          src="/images/wn_cover.jpg"
          title="We:Neighborhood"
          alt="WeNeighborhood"
          text="Designing the brewery that runs on the sun"
          path="/we-neighborhood"
        />

        <NextProject
          src="/images/self-matters_cover.jpg"
          title="SELF MATTERS"
          alt="Self Matters"
          text="Focusing on the importance of self care & wellness"
          path="/selfmatters"
        />
      </section>
    </>
  );
}
