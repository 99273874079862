import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function LaundaBefikar() {
  document.body.style.backgroundColor = "#fbf2d7";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Khota Sikka"
                src="/images/launda-befikar_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">LAUNDA BEFIKAR</div>
            <div className="tags">
              Concept &nbsp;|&nbsp; CGI &nbsp;|&nbsp; Video
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              Launda Befikar by MC Heam is a song that talks about an easy-going
              man who takes life’s struggles into his stride and finds
              positivity in the toughest of situations. Influenced by this, we
              created colourful and fresh visuals using a rainbow as the
              metaphor for the happiness he finds in his life.
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <iframe
                src="https://www.youtube.com/embed/8DMlJ3FVPl8"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                alt="Launda Befikar by MC Heam"
                title="Launda Befikar by MC Heam"
              />
            </div>

            <div className="hd_cover">
              <video
                src="/videos/launda-befikar_hd.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/scrolling-all_day_cover.jpg"
          title="Scrolling All Day"
          alt="Scrolling All Day"
          text="Moving tunes for when the world stood still"
          path="/scrolling-all-day"
        />

        <NextProject
          src="/images/khota-sikka_cover.jpg"
          title="Khota Sikka"
          alt="Khota Sikka"
          text="Khota Sikka (Worthless Coin) by MC Heam"
          path="/khota-sikka"
        />
      </section>
    </>
  );
}
