import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const Tira = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#fbf5f1";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <motion.div
          onAnimationComplete={() => setCanScroll(true)}
          className="scroll"
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <section className="project_grid_container">
          <video
                src="/videos/End Slate 16-9.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
          </section>

          <motion.section className="project_summary_container">
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, ...transition },
              }}
              className="title"
            >
              Tira Beauty
            </motion.div>
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.8, ...transition },
              }}
              className="tags"
            >
              Motion Design
            </motion.div>
          </motion.section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <motion.div
              initial={{
                opacity: 0,
                y: 80,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 1.5, ...transition },
              }}
              className="info"
            >
              <span>
              For the launch of Tira Beauty, Reliance Retail’s beauty platform, they approached us to bring alive their logo to unveil it across social media platforms.
              <br></br>

We came up with a 2D typography animation that is playful, lively and bursting with energy to capture the brand’s personality.
              </span>
            </motion.div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/T.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/I.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/R.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/A.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/Tira Wave Cropped.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/Tira Wave End Slate.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container">
          <video
                src="/videos/16-9 Tira Brand Reveal_040123.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
          </section>
        </motion.div>
      </div>

      <section className="project-nav">
      <PreviousProject
          src="/images/swati_cover.jpg"
          title="Swati"
          alt="Swati"
          text="Rebranding to bring alive a global vision"
          path="/swati"
        />

        <NextProject
          src="/images/wn_cover.jpg"
          title="We:Neighborhood"
          alt="WeNeighborhood"
          text="Designing the brewery that runs on the sun"
          path="/we-neighborhood"
        />
      </section>
    </>
  );
};

export default Tira;
