import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1 };

export default function Selfmatters() {
  document.body.style.backgroundColor = "#ffffff";

  return (
    <>
      <ScrollToTop />
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/self-matters_cover.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Self Matters</div>
            <div className="tags">
              Naming &nbsp;|&nbsp; Identity & Branding &nbsp;|&nbsp; Packaging
              Design &nbsp;|&nbsp; Website Design
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              Self Matters is a brand that aims to provide remedies for ailments
              that are often overlooked or neglected.
              <br />
              <br />
              Even though medicines are targeted to fix our bodies, the category
              is filled with brands that eliminate the human aspect and are cold
              and intimidating. With Self Matters, we wanted to create a brand
              that brings this human aspect into focus.
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Self Matters"
              src="/images/sm_hd_01.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_02.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_03.jpg"
              />
              <video
                src="/videos/sm_rectangle_am_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              The idea works across the brand - right through the naming,
              identity, packaging to the communication. The graphic form of a
              person takes centre stage in the brand language, becoming a visual
              hook that gives the brand a distinguishable shelf presence. It is
              supported by typography and colour palette that are pared back to
              be clean and refined. The visual system is flexible and
              extendable, allowing itself to be adapted across various sizes and
              orientations. The result is a brand that is perfectly balanced
              between being human and approachable while also retaining the
              integrity and trust of its category.
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Self Matters"
              src="/images/sm_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_04.jpg"
              />
              <video
                src="/videos/sm_rectangle_am_02.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_05.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_06.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Self Matters"
              src="/images/sm_hd_03.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_07.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_08.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_09.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Self Matters"
                src="/images/sm_rectangle_10.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover"
              alt="Self Matters"
              src="/images/sm_hd_04.jpg"
            />
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/pause_cover.jpg"
          title="PAUSE RESTROOMS"
          alt="Pause Restrooms"
          text="Reimagining the public restroom"
          path="/pause-restrooms"
        />

        <NextProject
          src="/images/friendly-laundry_cover.jpg"
          title="Friendly Laundry"
          alt="Friendly Laundry"
          text="Washing out the mundane with a purpose"
          path="/friendly-laundry"
        />
      </section>
    </>
  );
}
