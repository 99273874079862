import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

export default function OyeKake() {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#fde5c6";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">OYE KAKE</div>
            <div className="tags">
              Identity & Branding &nbsp;|&nbsp; Packaging Design
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                Oye Kake is a restaurant that brings authentic local fare from
                Amritsar to Mumbai. Dispelling the cliché that Punjabi food is
                primarily non-vegetarian, Oye Kake brings you vegetarian food
                (as found in many places across Amritsar) that is delicious and
                wholesome.
              </span>
              <br />
              <br />
              <span>
                Right from sourcing water from Amritsar to make sure that the
                taste of the kulchas doesn’t change - to having an impeccable
                variety of their beloved lassi, they leave no stone unturned to
                bring you the truest Punjabi experience. They approached us to
                refresh the complete brand and make it more relevant. The
                logotype takes inspiration from the Gurmukhi script, making it
                nostalgic with a modern touch.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover padding-bottom"
              alt="Oye Kake"
              src="/images/oye-kake_banner.gif"
            />
          </section>

          <section className="project_grid_container">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_02.jpg"
              />
              <video
                src="/videos/oye-kake_am_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_04.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_05.jpg"
              />
              {/* <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_06.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Oye Kake"
                src="/images/oye-kake_sq_07.jpg"
              /> */}
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                God Kake, the mascot, brings conversation into the brand while
                the other graphics we designed lend quirk and personality to it.
                Traditional motifs and colours spun into contemporary patterns
                finish off the brand language that comes alive in the space,
                packaging and other brand collaterals. The result is a brand
                that is bursting with energy, full of heart and warmth – just
                the way Punjabi food makes you feel.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover"
              alt="Oye Kake"
              src="/images/oye-kake_hd_01.jpg"
            />
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
           src="/images/tb_hd_02.jpg"
           title="Communiti Craft Beer"
           alt="Communiti Craft Beer"
           text="Brewing a mix of modernity and heritage"
           path="/truly-belgian"
        />

        <NextProject
          src="/images/ank_cover.jpg"
          title="A New Knot"
          alt="A New Knot"
          text="Binding momentous occasions with elegance"
          path="/a-new-knot"
        />
      </section>
    </>
  );
}
