import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/Navbar.scss";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="difference">
          <div className="navbar-container ">
            <Link to="/" className="navbar-logo"></Link>
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "hamburger" : "hamburger"} />
            </div>
          </div>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <Link to="/" className="r-logo"></Link>
          <li className="nav-item work">
            <Link to="/work" className="nav-links" onClick={closeMobileMenu}>
              Work
            </Link>
          </li>
          <li className="nav-item info">
            <Link to="/info" className="nav-links" onClick={closeMobileMenu}>
              Info
            </Link>
          </li>
          <li className="nav-item close">
            <div className="nav-links" onClick={closeMobileMenu}>
            {/* <i className="close" /> */}
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
