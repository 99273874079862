import React from "react";
// import ReactDOM from "react-dom";

export default function SocialMedia({ open, children }) {
  if (!open) return null;

  return (
    <>
      <div className="popup_container">{children}</div>
    </>
    // document.getElementById("portal")
  );
}
