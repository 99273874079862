import React from "react";
import "../assets/scss/App.scss";
import "../assets/scss/InfoSection.scss";

function InfoSection() {
  return (
    <>
      <section className="info-container" data-color="red">
        <h1 className="reveal-text">
        <span>We are your brand’s creative companions.</span> <br/>
        <span>We design your brand’s presence, tell your stories in compelling ways and help you stay relevant in a fast-paced world.</span>
        </h1>
      </section>
    </>
  );
}

export default InfoSection;
