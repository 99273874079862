import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1 };

export default function TrulyBelgian() {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);
  document.body.style.backgroundColor = "#f5e8cb";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
      <motion.div
          onAnimationComplete={() => setCanScroll(true)}
          className="scroll"
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Mumkin"
                src="/images/tb_hd_02.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">COMMUNITI CRAFT BEER</div>
            <div className="tags">
              Identity & Branding &nbsp;|&nbsp; Packaging Design &nbsp;|&nbsp;
              Website Design
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                Communiti craft beer has only one true agenda – to craft beer in
                the most natural way possible. This Belgian beer is 100%
                naturally brewed and absolutely free of any preservatives.
              </span>
              <br />
              <br />
              <span>
                Communiti achieves this by brewing the beer using centuries-old
                authentic Belgian traditions with state-of-art technology and
                the finest ingredients from across Europe.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/tb_logo_formation.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                This combination of science and tradition posed an interesting
                design challenge - one that we broached with a simple solution:
                balance. Balance between traditional (but not too uptight) and
                young (but not too frivolous). This balance was achieved by
                creating a central unit that followed the lines of traditional
                beer label designs - with traditional typography and hints of
                gold - and paired it with fresh colours that added a youthful
                look to the packaging.
              </span>
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/tb_bw_label_am.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Truly Belgian"
                src="/images/tb_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Truly Belgian"
                src="/images/tb_sq_02.jpg"
              />
              <video
                src="/videos/tb_hw_label_am.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/tb_ia_label_am.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Truly Belgian"
                src="/images/tb_sq_03.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover padding-bottom"
              alt="Truly Belgian"
              src="/images/tb_hd_01.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video src="/videos/tb_bw.mp4" autoPlay loop muted playsInline />
              <video src="/videos/tb_hw.mp4" autoPlay loop muted playsInline />
              <video src="/videos/tb_ia.mp4" autoPlay loop muted playsInline />
              <video
                src="/videos/tb_crafted.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Truly Belgian"
                src="/images/tb_sq_04.jpg"
              />
              <video
                src="/videos/tb_mobilescreen.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/tb_banner.jpg"
            />
          </section>
        </motion.div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/furry-essentials_cover.jpg"
          title="Furry Essentials"
          alt="Furry Essentials"
          text="Pampering is a must for our furry favourites"
          path="/furry-essentials"
        />

        <NextProject
          src="/images/oye-kake_cover.jpg"
          title="Oye Kake"
          alt="Oye Kake"
          text="Adding a zing of colour to amp up the flavour of culture"
          path="/oye-kake"
        />
      </section>
    </>
  );
}
