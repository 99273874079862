import React, { useEffect, useState } from "react";
// import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

// const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

// export default function FurryEssentials() {
//   const { scrollYProgress } = useViewportScroll();
//   const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

//   const [canScroll, setCanScroll] = useState(false);

//   useEffect(() => {
//     if (canScroll === false) {
//       document.querySelector("body").classList.add("no-scroll");
//     } else {
//       document.querySelector("body").classList.remove("no-scroll");
//     }
//   }, [canScroll]);
  export default function FurryEssentials() {
  document.body.style.backgroundColor = "#DBF5F9";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Furry Essentials"
                src="/images/furry-essentials_cover.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Furry Essentials</div>
            <div className="tags">
              Identity & Branding &nbsp;|&nbsp; Illustration &nbsp;|&nbsp; Space
              Design
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                Furry Essentials is a full-service salon that provides a range
                of grooming facilities for pets.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/furry-essentials_hd_01.jpg"
            />
          </section>

          <section className="project_info_container">
            <div className="info_left">
              With pets walking through their doors every day to be pampered and
              indulged, the space promises to never have a dull moment. We set
              out to capture this same sense into the identity, influencing the
              choice of typography. This, paired with the hand drawn
              illustrations, infuses the brand with character and playfulness –
              making it come alive.
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/furry-essentials_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Furry Essentials"
                src="/images/furry-essentials_rectangle_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Furry Essentials"
                src="/images/furry-essentials_rectangle_02.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/furry-essentials_hd_03.jpg"
            />
          </section>
          
          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Furry Essentials"
                src="/images/furry-essentials_rectangle_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Furry Essentials"
                src="/images/furry-essentials_rectangle_04.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/furry-essentials_hd_04.jpg"
            />
          </section>
          
          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Furry Essentials"
                src="/images/furry-essentials_rectangle_05.jpg"
              />
              <video
                src="/videos/furry-essentials_am_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/furry-essentials_hd_05.jpg"
            />
          </section>
          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover"
              alt="Truly Belgian"
              src="/images/furry-essentials_hd_06.jpg"
            />
          </section>

        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/friendly-laundry_cover.jpg"
          title="Friendly Laundry"
          alt="Friendly Laundry"
          text="Washing out the mundane with a purpose"
          path="/friendly-laundry"
        />

        <NextProject
          src="/images/tb_hd_02.jpg"
          title="Communiti Craft Beer"
          alt="Communiti Craft Beer"
          text="Brewing a mix of modernity and heritage"
          path="/truly-belgian"
        />
      </section>
    </>
  );
}
