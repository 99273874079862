import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const AAT = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);

  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  document.body.style.backgroundColor = "#fbf5f1";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <motion.div
          onAnimationComplete={() => setCanScroll(true)}
          className="scroll"
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <motion.div className="project__item__img">
                <motion.div
                  initial={{
                    // y: 0,
                    width: imageDetails.width,
                    height: imageDetails.height,
                  }}
                  animate={{
                    // y: "-50%",
                    width: "100%",
                    height: window.innerWidth > 1440 ? 800 : 400,
                    transition: { delay: 0.2, ...transition },
                  }}
                >
                  <motion.div
                    className="frame-single"
                    whileHover="hover"
                    transition={transition}
                  >
                    <motion.img
                      className="project__item__img"
                      alt="All About Them"
                      src="/images/aat_banner.jpg"
                    />
                  </motion.div>
                </motion.div>
              </motion.div>
            </figure>
          </section>

          <motion.section className="project_summary_container">
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, ...transition },
              }}
              className="title"
            >
              All About Them
            </motion.div>
            <motion.div
              initial={{
                opacity: 0,
                y: 50,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.8, ...transition },
              }}
              className="tags"
            >
              Identity & Branding &nbsp;|&nbsp; Illustration
            </motion.div>
          </motion.section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <motion.div
              initial={{
                opacity: 0,
                y: 80,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 1.5, ...transition },
              }}
              className="info"
            >
              <span>
                Animals bring with them a lot of happiness and selfless love
                into our lives. All about them is a nonprofit organization that
                is striving to help strays and abandoned pets – providing them
                with medical care, feeding them and finding homes – with the
                sole intention of trying to give back to them the love they
                deserve.
              </span>
            </motion.div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover"
              alt="All About Them"
              src="/images/aat_hd_01.jpg"
            />
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                To support their welfare work, they set up a lifestyle brand
                that would generate revenue and allow them to fuel their
                efforts. We helped them build this brand – including the brand
                identity, packaging and their launch collection.
              </span>
              <br />
              <br />
              <span>
                The main element of the branding is the logo symbol – a four
                legged ‘m’ that is a subtle nod to the animals. This symbol also
                sits in perfectly within the wordmark. The warm nudes and browns
                became a natural choice of colour to reinforce the humane,
                compassionate aspect of the brand.
              </span>
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/aat_sq_01.jpg"
              />

              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/aat_sq_05.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="hd_cover padding-bottom"
              alt="All About Them"
              src="/images/aat_hd_02.jpg"
            />
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/aat_sq_07.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="All About Them"
                src="/images/aat_sq_11.jpg"
              />
            </div>
          </section>
        </motion.div>
      </div>

      <section className="project-nav">
      <section id="previous_project">
          <Link className="previous_project" to="/awakeful">
            <div className="previous-tab">
              <h3>Previous project</h3>
              <div className="arrow-wrapper">
                <div className="previous-arrow" />
              </div>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              className="details"
            >
              <motion.figure className="cards__item__pic-wrap">
                <motion.video
                  className="cards__item__img"
                  alt="Awakeful"
                  autoPlay
                  loop
                  muted
                  playsInline
                  src="/videos/awakeful_hd_01.mp4"
                  transition={transition}
                />
              </motion.figure>
              <motion.div className="cards__item__info">
                <div className="title">Awakeful</div>
                <div className="text">
                  Opening your eyes to an exciting world
                </div>
              </motion.div>
            </motion.div>
          </Link>
        </section>

        <NextProject
          src="/images/mumkin_cover.jpg"
          title="Mumkin"
          alt="Mumkin"
          text="Instilling hope one smile at a time"
          path="/mumkin"
        />
      </section>
    </>
  );
};

export default AAT;
