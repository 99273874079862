import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function AtlBtlFtl() {
  document.body.style.backgroundColor = "#fbf2d7";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="ATL BTL FTL"
                src="/images/atl_btl_ftl_banner_01.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">Aatli Baatli Futli</div>
            <div className="tags">
              Music &nbsp;|&nbsp; Songwriting &nbsp;|&nbsp; Concept & Direction
              &nbsp;|&nbsp; CGI &nbsp;|&nbsp; Video
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                This ties in with the essence of the song, which is to break
                barriers and to challenge the status quo, to go back to a fresh,
                unfiltered approach. We believe in creating work that explores
                new avenues, is unshackled and unique, is clear of baggage and
                free of the tried-and-tested approach. The song captures the
                core of our philosophy and our attitude. This song is our
                anthem.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <iframe
                title="ATL BTL FTL (Aatli Baatli Futli) by ROCKING.COMPANY - Official Music Video"
                src="https://www.youtube.com/embed/5F741BarP3U"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                alt="ATL BTL FTL (Aatli Baatli Futli) by ROCKING.COMPANY - Official Music Video"
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                This ties in with the essence of the song, which is to break
                barriers and to challenge the status quo, to go back to a fresh,
                unfiltered approach. We believe in creating work that explores
                new avenues, is unshackled and unique, is clear of baggage and
                free of the tried-and-tested approach. The song captures the
                core of our philosophy and our attitude. This song is our
                anthem.
              </span>
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/atl_btl_ftl_am_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/atl_btl_ftl_am_02.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/atl_btl_ftl_am_03.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="ATL BTL FTL"
                src="/images/atl_btl_ftl_sq_02.jpg"
              />
              <video
                src="/videos/atl_btl_ftl_am_04.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/atl_btl_ftl_am_05.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/atl_btl_ftl_am_06.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="ATL BTL FTL"
                src="/images/atl_btl_ftl_sq_03.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="ATL BTL FTL"
                src="/images/atl_btl_ftl_sq_04.jpg"
              />
              <video
                src="/videos/atl_btl_ftl_am_07.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <video
                src="/videos/atl_btl_ftl_am_08.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="ATL BTL FTL"
                src="/images/atl_btl_ftl_sq_05.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <LazyLoadImage
              className="project__item__img hd_cover"
              alt="ATL BTL FTL"
              src="/images/atl_btl_ftl_hd_01.jpg"
            />
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/kinstrukto_cover.jpg"
          title="Kinstrukto"
          alt="Kinstrukto"
          text="Time to unblock creativity"
          path="/kinstrukto"
        />

        <NextProject
          src="/images/quarantine_cover.jpg"
          title="QUARANTINE SOUNDTRACKS"
          alt="Quarantine Soundtracks"
          text="Moving tunes for when the world stood still"
          path="/quarantine-soundtracks"
        />
      </section>
    </>
  );
}
